import { PulsingText } from '@tofu/shared/ui/atoms/pulsing-text'

import { PromoBanner } from '@tofu/shared/ui/atoms/promo-banner'
import { TShopPromoBanner } from './shop-promo-banner.types'
import { renderBannerRichText, isValuesEmpty } from './shop-promo-banner.utils'

export const ShopPromoBanner: TShopPromoBanner = ({
  defaultTextArray,
  overrideTextArray,
  colorScheme
}) => {
  let renderedBannerText = null

  // use the page banners
  if (!isValuesEmpty(overrideTextArray)) {
    renderedBannerText = renderBannerRichText(overrideTextArray)
  }

  // default to the global banners
  if (isValuesEmpty(overrideTextArray)) {
    renderedBannerText = renderBannerRichText(defaultTextArray)
  }

  if (renderedBannerText?.length === 1) {
    return (
      <PromoBanner colorScheme={colorScheme}>{renderedBannerText}</PromoBanner>
    )
  }

  return (
    <PromoBanner colorScheme={colorScheme} data-testid='pulsing-banner-text'>
      <PulsingText>{renderedBannerText}</PulsingText>
    </PromoBanner>
  )
}
