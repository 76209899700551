import React from 'react'
import { logger } from '@tofu/shared/utils/sentry'
import {
  TErrorBoundaryProps,
  TErrorBoundaryState
} from './error-boundary.types'

export class ErrorBoundary extends React.Component<
  TErrorBoundaryProps,
  TErrorBoundaryState
> {
  constructor(props: TErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  override componentDidCatch(error: Error, info: unknown) {
    if (this.props.onComponentDidCatch) {
      this.props.onComponentDidCatch(error, info)
    }
    logger(error)
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}
