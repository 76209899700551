import dynamic from 'next/dynamic'

import { BagIcon } from '@tofu/shared/ui/atoms/icons'
import { Button } from '@tofu/shared/ui/atoms/button'
import { NotificationBadge } from '@tofu/shared/ui/atoms/notification-badge'

import { useBasket } from '@tofu/apps/shop/providers/basket'
import { trackEvent } from '@tofu/shared/utils/analytics'
import { getItemCount, getSubtotal } from '@tofu/shared/utils/cart'
import { formatPrice } from '@tofu/shared/utils/format-currency'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useConstants } from '@tofu/shared/providers/constants-provider'

const DynamicBasketDrawerContainer = dynamic(() =>
  import('../shop/basketDrawer').then((mod) => mod.BasketDrawerContainer)
)

const BasketIcon = ({ badgeColorScheme }) => {
  const { deliveryCharge } = useConstants()
  const { cart, isOpen, toggle } = useBasket()
  const itemCount = getItemCount(cart.items)
  const subtotal = getSubtotal({ basket: cart, deliveryCharge })

  const handleClick = () => {
    trackEvent('basket.opened', {})
    toggle(true)
  }

  return (
    <>
      <Button
        variant='link'
        fontSize='sm'
        pl={4}
        textDecoration='none'
        fontWeight='regular'
        _hover={{ textDecoration: 'none' }}
        onClick={handleClick}
        aria-label={`basket with ${
          itemCount || 0
        } items, subtotal ${formatPrice(subtotal)}`}
      >
        <NotificationBadge
          badgeContent={itemCount || null}
          colorScheme={badgeColorScheme}
        >
          <span id='menu-basket-price' aria-hidden>
            {formatPrice(subtotal)}
          </span>
          <BagIcon ml={2} boxSize={7} />
        </NotificationBadge>
      </Button>
      {
        // TODO change this to use useModal, no embed like this :(
      }
      {isOpen ? (
        <DynamicBasketDrawerContainer isOpen={isOpen} onClose={toggle} />
      ) : null}
    </>
  )
}

export { BasketIcon }

export default BasketIcon
