import chakraTheme from '@tofu/shared/theme'
import { Text } from '@tofu/shared/ui/atoms/text'

import { TPromoBanner } from './promo-banner.types'

export const PromoBanner: TPromoBanner = ({
  colorScheme = 'blackAlpha',
  ...props
}) => {
  const bg = chakraTheme['colors'][colorScheme]?.main
  const color = chakraTheme['colors'][colorScheme]?.contrastText

  return (
    <Text
      as='span'
      textAlign='center'
      bg={bg}
      color={color}
      w='100%'
      textStyle='body-sm'
      height={['45px', '40px']} // using height instead of padding so that page content dosen't shift
      display='flex'
      alignItems='center'
      justifyContent='center'
      fontWeight='book'
      px={4}
      {...props}
    />
  )
}
