const extrasList = ['BREAKFAST', 'SIDE', 'SMOOTHIE', 'TREAT', 'TRAYBAKE']
const mealsList = ['MEAL', 'PIZZA']

const isExtra = (product_type) => extrasList.includes(product_type)
const isMeal = (product_type) => mealsList.includes(product_type)

const variantSelector = ({
  portionSize,
  variantType = 'SUBSCRIPTION',
  variants
}) => {
  // Find the correct variant
  const variant = variants.find(
    (item) =>
      item.portion_size === portionSize && item.variant_type === variantType
  )

  // Return the variant
  return variant || null
}

const getExtrasPrice = (products) => {
  const BREAKFAST = products.find(
    ({ product_type }) => product_type === 'BREAKFAST'
  )?.variant?.price

  const SIDE = products.find(({ product_type }) => product_type === 'SIDE')
    ?.variant?.price

  const SMOOTHIE = products.find(
    ({ product_type }) => product_type === 'SMOOTHIE'
  )?.variant?.price

  const TREAT = products.find(({ product_type }) => product_type === 'TREAT')
    ?.variant?.price

  return {
    BREAKFAST,
    SIDE,
    SMOOTHIE,
    TREAT
  }
}

const getImageSrc = (index) => {
  if (index === 1) {
    return '/img/full-star.svg'
  }
  if (index === 0) {
    return '/img/empty-star.svg'
  }
  return '/img/half-star.svg'
}

const buildStarArray = (number) => {
  // get whole numbers and check for decimal value
  const wholeStars = Math.floor(number)
  const halfStar = number % 1

  const wholeStarsArray = Array.from({ length: wholeStars }).fill(1)

  // append half stars to wholeStarsArray
  if (halfStar > 0) {
    wholeStarsArray.push(halfStar)
  }

  // append zeros to render empty stars
  if (wholeStarsArray.length > 2) {
    const remainder = 5 - wholeStarsArray.length
    const emptyArray = Array.from({ length: remainder }).fill(0)
    return [...wholeStarsArray, ...emptyArray]
  }

  return wholeStarsArray
}

const normaliseVariantData = ({ products, portionSize = 'DOUBLE_PORTION' }) =>
  products
    .map(({ variants, ...rest }) => {
      const obj = rest // copy object with variants field omitted
      const variant = variantSelector({
        portionSize,
        variants
      })
      obj.variant = variant // return the correct portion size variant as new field in copied object
      return variant && obj // Only return object if variant exists
    })
    .filter((i) => i)

export {
  buildStarArray,
  extrasList,
  getExtrasPrice,
  getImageSrc,
  isExtra,
  isMeal,
  mealsList,
  normaliseVariantData,
  variantSelector
}
