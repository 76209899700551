import { useEffect } from 'react'
import { useSession } from '@tofu/apps/shop/providers/session'

let userIdentifiedThisSession
let identifiedUser = ''

export const useIdentify = () => {
  const { data } = useSession()
  const customerId = data?.customerId ?? null

  const isNewCustomerId = customerId && customerId !== identifiedUser

  if (isNewCustomerId) {
    userIdentifiedThisSession = false
  }

  // useSession returns undefined initially on each route change so we can't use the usePrevious
  // hook to prevent spamming identify calls. Instead, mutate a variable in this module's scope
  // to ensure we only identify once.
  const shouldIdentify = !userIdentifiedThisSession && isNewCustomerId

  useEffect(() => {
    if (window.rudderanalytics?.identify && shouldIdentify) {
      window.rudderanalytics.identify(customerId)
      userIdentifiedThisSession = true
      identifiedUser = customerId
    }
  }, [shouldIdentify, customerId])
}
