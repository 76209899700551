import { ReactElement } from 'react'
import every from 'lodash/fp/every'
import isNil from 'lodash/fp/isNil'
import { RichText, RichTextBlock } from 'prismic-reactjs'

// TODO: move this to the shared data utils
export const isValuesEmpty = every(isNil)

type TRenderBannerRichText = (
  richTextBlockArray: RichTextBlock[] | undefined
) => ReactElement[] | null

export const renderBannerRichText: TRenderBannerRichText = (
  // istanbul ignore next
  richTextBlockArray = []
) =>
  richTextBlockArray.map((textObject: RichTextBlock, i: number) => (
    <RichText key={i} render={[textObject]} />
  ))
