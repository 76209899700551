import { useEffect, useRef, Children } from 'react'
import { useCycle } from 'framer-motion'
import { Fade, useBoolean } from '@chakra-ui/react'

import { TPulsingText } from './pulsing-text.types'

export const PulsingText: TPulsingText = ({ children, cycleTime = 3000 }) => {
  const arrayChildren = Children.toArray(children)
  const timeToHide = cycleTime - 500
  const [show, setShow] = useBoolean(true)
  const [element, nextElement] = useCycle(...arrayChildren)

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const interval = useRef<ReturnType<typeof setInterval> | null>(null)

  const startHideTimer = () =>
    (timer.current = setTimeout(() => setShow.off(), timeToHide))

  const showText = () => {
    setShow.on()
    startHideTimer()
  }

  useEffect(() => {
    showText() // to show on initial render

    interval.current = setInterval(() => {
      showText()
      nextElement()
    }, cycleTime)
    return () => {
      if (interval.current && timer.current) {
        clearInterval(interval.current)
        clearTimeout(timer.current)
      }
    }
    // only runnin on mount/unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Fade in={show}>{element}</Fade>
}
