import map from 'lodash/fp/map'
import curry from 'lodash/fp/curry'

import config from '@tofu/apps/shop/constants/env'
import { isMeal } from './products'

const createUrlToAccount = (relativeUrl) =>
  `${config.account_base_url}${relativeUrl}`

const createUrlToCheckout = (relativeUrl) =>
  `${config.checkout_base_url}${relativeUrl}`

const getPortionSizes = (product_type, portionSize) => {
  if (isMeal(product_type)) {
    return portionSize
  }
  if (product_type === 'TRAYBAKE') {
    return 'DOUBLE_PORTION'
  }
  return 'SINGLE_PORTION'
}

const getProductVariantIdByPortionSize = curry((portionSize, product) => {
  const variant = getPortionSizes(product.product_type, portionSize)
  return product.variants[variant].id
})

export const getVariantIdsFromProductsByPortionSize = (
  products,
  portionSize = 'DOUBLE_PORTION'
) => map(getProductVariantIdByPortionSize(portionSize))(products)

const createBasketLink = ({
  variants,
  location = 'shop', // default location to `shop`
  frequency = '2', // default frequency to 2
  discountCode,
  exp_id,
  path
}) =>
  `${config.api_base_url}/carts?i=${variants.join(',')}${
    discountCode ? `&d=${discountCode}` : ''
  }&f=${frequency}${path ? `&n=${path}` : `&r=${location}`}${
    exp_id ? `&exp_id=${exp_id}` : ''
  }`

export { createBasketLink, createUrlToAccount, createUrlToCheckout }
