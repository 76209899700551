import { Circle } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { TNotificationBadge } from './notification-badge.types'

export const NotificationBadge: TNotificationBadge = ({
  children,
  badgeContent,
  colorScheme = 'whiteAlpha',
  ...rest
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!badgeContent) return <>{children}</>

  return (
    <Box position='relative' {...rest}>
      <Circle
        size='19px'
        bg={`${colorScheme}.main`}
        color={`${colorScheme}.contrastText`}
        bottom='-8px'
        right='-8px'
        position='absolute'
        fontSize='xs'
        fontWeight='bold'
        zIndex='1'
      >
        {badgeContent}
      </Circle>
      {children}
    </Box>
  )
}
