import Link from 'next/link'
import { Container } from '@chakra-ui/react'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { TError } from './error.types'

export const Error: TError = ({ message }) => {
  return (
    <Container maxW='container.xl' my={[8, 12, 20]}>
      <Box maxWidth='container.md'>
        <Text as='h1' textStyle='h1'>
          Holy{' '}
          <Text as='span' textStyle='h1-condensed'>
            Guacamole!
          </Text>
        </Text>
        <Text textStyle='body-xl' mt={2}>
          {message || 'Something has gone wrong, please try again.'}
        </Text>
        <VStack spacing={3} mt={8} alignItems='start'>
          <Text textStyle={['body-sm', 'body-md']}>
            Here are some links that might be useful:
          </Text>
          <Link href='/' passHref>
            <Button variant='link'>Home</Button>
          </Link>
          <Link href='/shop' passHref>
            <Button variant='link'>Shop</Button>
          </Link>
          <Link href='/contact' passHref>
            <Button variant='link'>Contact Us</Button>
          </Link>
        </VStack>
      </Box>
    </Container>
  )
}
