import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import head from 'lodash/fp/head'

const getFirstItemInArray = map(head)
const getPromoBannerText = get('promo_banner_text')
const getPromoBannerTexts = map(getPromoBannerText)

const getDefaultPromoBannerText = get('default_promo_banner_text')
const getDefaultPromoBannerTexts = map(getDefaultPromoBannerText)

export const getPromoBannerTextArray = flow(
  getPromoBannerTexts,
  getFirstItemInArray
)

export const getFallbackTextArray = flow(
  getDefaultPromoBannerTexts,
  getFirstItemInArray
)
